<template>
    <div>
        <div>
            <el-form :inline="true" :model="filter" class="demo-form-inline">
                <el-form-item label="图片标签">
                    <el-select v-model="filter.tags" clearable multiple placeholder="请选择">
                        <el-option v-for="item in tagsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button type="success" @click="addPic">+ 新建图片</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" :row-key="id" style="width: 100%">
            <el-table-column prop="id" label="id" width="100"></el-table-column>
            <el-table-column prop="tags" label="标签" width="180">
                <template slot-scope="scope">
                    <el-tag v-for="(item, index) in scope.row.bgTagList" :key="index">{{ item.value }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="file" label="图片" width="180">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="scope.row.file" :preview-src-list="[scope.row.file]"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="createdAt" label="上传时间" min-width="180">
                <template slot-scope="scope">
                    {{ new Date(scope.row.createdAt).toLocaleString() }}
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleModify(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="增加图片" :visible.sync="dialogVisible">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="标签" prop="tags" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-select clearable multiple v-model="form.tags" placeholder="请选择">
                        <el-option v-for="item in tagsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-upload class="upload-demo" drag action="/admin-api/upload" :on-success="handleSetClampUploadSuccess">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或
                            <em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="图片" prop="imgSrc" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="form.imgSrc" placeholder="在上方上传文件，将自动填充此部分"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import F from './fetch';
import { confirmation } from '@/utils/decorator';

export default {
    name: 'ResourceList',
    components: {},
    data() {
        return {
            tableData: [],
            filter: {
                pageNo: 1,
                pageSize: 9999,
                tags: [],
            },
            tagsOptions: [],
            form: {
                id: undefined,
                tags: [],
                imgSrc: '',
            },
            dialogVisible: false,
        };
    },
    async created() {
        F.fetchAllImgTag().then(data => {
            this.tagsOptions = data.map(item => {
                return {
                    label: item.value,
                    value: item.id,
                };
            });
        });

        this.fetchList();
    },
    mounted() {},
    methods: {
        handleModify(row) {
            this.dialogVisible = true;
            this.getSingleImgData(row.id);
        },
        addPic() {
            this.dialogVisible = true;
            this.form = {
                tags: [],
                imgSrc: '',
                id: undefined,
            };
        },
        getSingleImgData(id) {
            F.singleBgImgs(id).then(data => {
                this.form = {
                    tags: data.bgTagList.map(item => {
                        return item.id;
                    }),
                    imgSrc: data.file,
                    id,
                };
            });
        },

        save() {
            this.$refs.form.validate().then(() => {
                if (this.form.id) {
                    F.modifyAllBgImgs(this.form).then(e => {
                        this.fetchList(this.filter);
                        this.dialogVisible = false;
                        this.$message.success('成功');
                    });
                } else {
                    F.addBgImgs(this.form).then(e => {
                        this.fetchList(this.filter);
                        this.dialogVisible = false;
                        this.$message.success('成功');
                    });
                }
            });
        },

        handleSetClampUploadSuccess(res, file) {
            this.form.imgSrc = `/upload/${res.data}`;
        },

        onSearch() {
            this.filter.pageNo = 1;
            this.fetchList(this.filter);
        },

        fetchList() {
            F.fetchAllBgImgs(this.filter).then(data => {
                this.tableData = data.data.list;
            });
        },
        editAmount(record, type) {
            this.editForm = {
                amount: '',
                remark: '',
                id: record.id,
                type: type,
            };
            this.visible = true;
        },

        // @confirmation('确定添加机器预算单吗？')
        openApproveUrl(record) {},

        mappingArr(arr) {
            return arr.map(item => item.id || item.value);
        },

        handleEdit(record) {
            const val = JSON.parse(JSON.stringify(record));
            this.formSetClamp = val;
            this.dialogSetClampFormVisible = true;
        },
        @confirmation('删除？')
        handleDelete(record) {
            F.deleteBgImgs(record.id).then(data => {
                this.fetchList();
                this.$message.success('成功');
            });
        },
    },
};
</script>

<style scoped>
.title {
    display: flex;
}
.left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 20px;
    flex: 1;
}
.right {
    width: 300px;
    padding: 2px 20px;
    display: flex;

    align-items: flex-start;
    justify-content: flex-end;
}
</style>
